import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=65877ddd&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=65877ddd&prod&scoped=true&lang=css&"
import style1 from "./Index.vue?vue&type=style&index=1&id=65877ddd&prod&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65877ddd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VApp,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VContainer,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VRadio,VRadioGroup,VRow,VSelect,VSpacer,VTextField})
