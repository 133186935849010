import { render, staticRenderFns } from "./Widget2.vue?vue&type=template&id=2b4abe56&"
import script from "./Widget2.vue?vue&type=script&lang=js&"
export * from "./Widget2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports