import { render, staticRenderFns } from "./TransferSettings.vue?vue&type=template&id=6e92a0b8&scoped=true&"
import script from "./TransferSettings.vue?vue&type=script&lang=js&"
export * from "./TransferSettings.vue?vue&type=script&lang=js&"
import style0 from "./TransferSettings.vue?vue&type=style&index=0&id=6e92a0b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e92a0b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VApp,VBtn,VDatePicker,VMenu,VSelect,VSpacer,VTextField,VTextarea})
