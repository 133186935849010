import { render, staticRenderFns } from "./PersonalInformation.vue?vue&type=template&id=bbf2d552&scoped=true&"
import script from "./PersonalInformation.vue?vue&type=script&lang=js&"
export * from "./PersonalInformation.vue?vue&type=script&lang=js&"
import style0 from "./PersonalInformation.vue?vue&type=style&index=0&id=bbf2d552&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbf2d552",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDatePicker,VMenu,VSelect,VTextField})
