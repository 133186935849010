<template>
  <div>
    <div class="row pl-8 pr-8 pt-5 pb-5">
      <div class="col-md-12">
        <h4>Exam information</h4> <hr/>
        </div>
      <div class="col-md-6">
       <b> Total courses: </b>
        <span class="count_number_badge">{{ stats.total_course_count || 0 }}</span>
       </div>
      <div class="col-md-6">
        <b> Total students: </b>
        <span class="count_number_badge">{{ exam.total_student }}</span>
      </div>
      <div class="col-md-6">
       <b> Confirmation status: </b>
        <span class="badge badge-success" v-if="exam.is_exam_confirmed">
          Confirmed
        </span>
        <span class="badge badge-warning" v-else>
          Not confirmed
        </span><br/>
       </div>
      <div class="col-md-6">
        <b> Exam confirmation notification: </b> <span class="badge badge-success" v-if="exam && exam.is_notified">
        Yes
      </span>
        <span class="badge badge-warning" v-else>
          No
        </span><br/>
      </div>
      <div class="col-md-6">
        <b> Exam confirmed date: </b> {{exam.formatted_exam_confirmed_date || 'N/A'}}<br/>
      </div>
      <div class="col-md-6">
        <span class="font-weight-bold">Attendance days: </span>
        <span class="font-weight-medium">
          {{exam.total_attendance_days || 0}}
        </span><br/>
      </div>
      <div class="col-md-6">
        <b>Internal evaluations </b>
        <span class="badge badge-success" v-if="exam.show_internal_evaluation">Yes</span>
        <span class="badge badge-warning" v-else>No</span><br/>
      </div>
      <div class="col-md-12">
        <h4>Result information</h4> <hr/>
      </div>
      <div class="col-md-6">
         <b>Published </b>
        <span class="badge badge-success" v-if="exam && exam.result_status=='published'">
          Yes
        </span>
        <span class="badge badge-warning" v-else>
          No
        </span><br/>
      </div>
      <div class="col-md-6">
        <b>Published date: </b>
        <span class="font-weight-medium" v-if="exam.result_publish_date">
          {{exam.result_publish_date}}
        </span>
        <br/>
      </div>
      <div class="col-md-6">
        <b> Notified: </b>
        <span class="badge badge-success" v-if="exam && exam.result_status=='published'">Yes</span>
        <span class="badge badge-warning" v-else>No</span><br/>
      </div>
      <div class="col-md-6">
        <b> Notified date: </b>{{ exam.result_publish_date || 'N/A' }}<br/>
      </div>


      <div class="col-md-6">
        <b> Ranking type </b> <span class="font-weight-medium">{{ exam.generate_sequential_rank ? 'Serial' : 'Discrete' }}</span><br/>
      </div>
      <div class="col-md-6">
        <b> Grading system </b>
        <span class="font-weight-medium">
          {{exam.grade_point_title}}
        </span><br/>
      </div>
      <div class="col-md-6">
        <b> Overall grading system: </b> {{ exam.overall_grade_point_title || 'N/A' }}<br/>
      </div>

      <div class="col-md-6">
        <b> Additional assessment system: </b>
        <span class="badge badge-success" v-if="exam.has_additional_assessment">
          Yes
        </span>
        <span class="badge badge-warning" v-else>
          No
        </span><br/>
      </div>

      <div class="col-md-12">
        <h4>Linked previous exams</h4> <hr/>
      </div>
      <div class="col-md-6">
        <b> First terminal: </b> <span class="font-weight-medium">
        {{exam.previous_exam_title || 'N/A'}}
      </span><br/>
      </div>
      <div class="col-md-6">
        <b> Second terminal: </b>  {{exam.previous_exam_second_title || 'N/A'}}<br/>
      </div>
    </div>
<!--    <div class="row pl-8 pr-8 pt-5 pb-5">-->
<!--      <div class="col-md-12">-->
<!--        <div class="border-graph">-->
<!--          <h4>Result summary</h4>-->
<!--          <apexchart v-if="!loading" width="100%" height="250px" type="donut" :options="chartOptions" :series="series"></apexchart>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>
import ExamService from "@/core/services/exam/ExamService";
const exam=new ExamService();
export default {
  name: "CounterExam1",
  props:['exam'],
  data() {
    return {
      chartOptions: {
        chart: {
          id: 'vuechart-example',
        },
        labels: [],
        dataLabels: {
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex]
          },
        },
      },
      filter:{

      },
      series: [],
      stats: {
        total_student_count: null,
        total_course_count: null,

      },
      passed_out_student_stats:null,
      program_stats:[],
      library_stats:{
        total_book_counts:null,
        total_issued_counts:null,
      },
      open_admission_programs_lists:[],
      new_application_lists:[],
      loading:false
    }
  },
  mounted() {
    this.getExamDetailStats();
  },
  methods: {
    getExamDetailStats(){
      if(this.exam){
        this.loading=true;
        exam
            .getExamSummaryStats(this.exam.id,this.filter)
            .then((response) => {
              this.stats=response.data.exam_stats_count;
            })
            .catch((err) => {

            })
            .finally(() => {
              this.loading=false;
            });
      }
    },
  },
}
</script>