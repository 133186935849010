import Apiservice from '@/core/services/api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class ExamService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/exams`;

    }

    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    getCourseExam(programId, gradeId) {
        let url = `${API_URL}user/courses-exam/${programId}/${gradeId}`
        return apiService.get(url);
    }

    getClassExam(programId, gradeId,data) {
        let url = `${API_URL}user/class-exams/${programId}/${gradeId}`;
        let param = {
            params: data
        }
        return apiService.query(url,param);
    }

    getExamByClassId(classId) {
        let url = `${this.#api}/${classId}/assigned/classes`
        return apiService.get(url);
    }


    paginate(data, index = null) {
        let url = `${this.#api}`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    syncCourses(id, data) {
        let url = `${this.#api}/${id}/sync-courses`
        return apiService.post(url, data);

    }

    saveTimetable(id, data) {
        let url = `${this.#api}/${id}/save-course-timetable`
        return apiService.post(url, data);

    }

    getAssignedCourses(examId) {
        let url = `${this.#api}/assigned/${examId}/courses`
        return apiService.get(url);
    }

    getUnAssignedCourses(examId) {
        let url = `${this.#api}/unassigned/${examId}/courses`
        return apiService.get(url);
    }

    getAssignedPivotCourses(examId) {
        let url = `${this.#api}/pivot/assigned/${examId}/courses`
        return apiService.get(url);
    }

    getExamAssignedStudents(examId) {
        let url = `${this.#api}/${examId}/assigned-students`
        return apiService.get(url);
    }

    saveExamAssignedStudents(examId, data) {
        let url = `${this.#api}/${examId}/assigned-students`
        return apiService.post(url, data);
    }

    getAssignedStudents(examId) {
        let url = `${this.#api}/${examId}/assigned/students`
        return apiService.get(url);
    }

    getAssignedStudentsByCourse(examId, courseId, data) {
        let url = `${this.#api}/${examId}/course/${courseId}/students`
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    exportExamAttendance(examId,courseId,data={})
    {
        let url = `${this.#api}/export/exam/${examId}/student-attendance/by/course/${courseId}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    previewExamAttendance(examId,courseId,data={})
    {
        let url = `${this.#api}/preview/exam/${examId}/student-attendance/by/course/${courseId}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }


    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    calculateTotal(id) {
        let url = `${this.#api}/${id}/calculate/total`
        return apiService.get(url);
    }

    recalculateAllCourse(id) {
        let url = `${this.#api}/${id}/recalculate/all`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getBySlug(slug) {
        let url = `${this.#api}/by-slug/${slug}`
        return apiService.get(url);
    }

    markAs(examId, type) {
        let url = `${this.#api}/${examId}/mark-as/${type}`
        return apiService.get(url);
    }

    importResult(examId, data) {
        let url = `${this.#api}/${examId}/import-result`
        return apiService.post(url, data);
    }

    importCourseResult(examId, data) {
        let url = `${this.#api}/${examId}/import-course-result`
        return apiService.post(url, data);
    }

    importSummaryResult(examId, data) {
        let url = `${this.#api}/${examId}/import-summary-result`
        return apiService.post(url, data);
    }

    notifyStudents(examId, data) {
        let url = `${this.#api}/${examId}/notify-students`
        return apiService.post(url, data);
    }

    sendExamResultPublishedEmail(examId) {
        let url = `${this.#api}/${examId}/result-published-mail/send`
        return apiService.get(url);
    }

    sendExamResultConfirmedEmail(examId) {
        let url = `${this.#api}/${examId}/result-confirmed-mail/send`
        return apiService.get(url);
    }
    getAllStudentExam(userId, data){
        let url = `${this.#api}/${userId}/by/students`;
        
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getExamSummaryStats(examId,data={}){
        let url = `${this.#api}/summary/${examId}/stats`;

        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getExamPerformanceStats(userId,data={}){
        let url = `${this.#api}/performance/${userId}/stats`;

        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getExamPerformanceStatsInTableView(userId,data={}){
        let url = `${this.#api}/performance/${userId}/stats/table-view`;

        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getExamByStudentGradeId(studentGradeId=null,studentId=null,data=null)
    {
        let url = `${this.#api}/${studentId}/get-by-student-grade/${studentGradeId}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

}
