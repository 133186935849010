import { render, staticRenderFns } from "./Vehicles.vue?vue&type=template&id=329ce211&scoped=true&"
import script from "./Vehicles.vue?vue&type=script&lang=js&"
export * from "./Vehicles.vue?vue&type=script&lang=js&"
import style0 from "./Vehicles.vue?vue&type=style&index=0&id=329ce211&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329ce211",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VApp,VSkeletonLoader})
