var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column pt-4 bgi-size-cover bgi-no-repeat rounded-top"},[_vm._m(0),(_vm.notifications.length > 0)?_c('div',[_c('perfect-scrollbar',{staticClass:"navi scroll my-4",staticStyle:{"max-height":"40vh","position":"relative"}},[_vm._l((_vm.notifications),function(item,i){return [_c('a',{key:i,staticClass:"navi-item cursor-pointer m-2",class:{ read: !item.is_viewed },on:{"click":function($event){$event.preventDefault();return _vm.viewNotification(item)}}},[_c('div',{staticClass:"navi-link"},[_c('div',{staticClass:"navi-icon"},[_c('div',{staticClass:"symbol symbol-40",class:("symbol-light-" + (item.status == 'pending'
              ? 'warning'
              : item.status == 'ready'
              ? 'primary'
              : 'success'))},[_c('span',{staticClass:"symbol-label"},[_c('span',{staticClass:"svg-icon svg-icon-lg",class:("symbol-light-" + (item.status == 'pending'
                      ? 'warning'
                      : item.status == 'ready'
                      ? 'primary'
                      : 'success'))},[_c('i',{class:("fas " + (_vm.checkNotificationType(item)))})])])])]),_c('div',{staticClass:"navi-text ml-7"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(item.formatted_date_sent)+" "+_vm._s(item.formatted_time)+" "+_vm._s(item.status)+" ")])])])])]})],2)],1):_vm._e(),(_vm.notifications.length == 0)?_c('div',{staticClass:"d-flex flex-center item- font-weight-bold m-3"},[_c('h5',[_vm._v("No notification found")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-center item- font-weight-bold"},[_c('router-link',{staticClass:"text-dark text-hover-primary font-size-lg mb-2",attrs:{"to":{ name: 'all-notification' }}},[_vm._v(" View All ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"rounded-top border-bottom px-3 pb-2"},[_c('span',{},[_vm._v(" Notifications ")])])}]

export { render, staticRenderFns }