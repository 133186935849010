<template>
  <v-app>
    <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="dialog"
    >
      <v-toolbar
          style="background: #04458c !important;"
      >
        <v-row>
          <v-col cols="6 pt-5 ">
            <h4>Add students</h4>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn icon  @click="hideModal">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-card>

        <v-card-text>
          <div class="row ">
            <div class="col-3">
              <v-text-field outlined dense v-model="search.title" label="Search by CRN number, first name, last name, email"></v-text-field>
            </div>
            <div class="col-3">
              <v-btn depressed class="btn btn-primary text-white" @click="getStudentExamFromClass">Search</v-btn>
            </div>
            <div class="col-6 text-right">
              <v-btn :loading="loading"
                     class="text-white ml-2 btn btn-primary"
                     @click="save"
              >
                Save
              </v-btn>
            </div>
          </div>
          <div class="row ">
            <div class="col-12">
              <div  v-if="loading">
                <v-skeleton-loader
                    v-bind="attrs"
                    type="list-item-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                    v-bind="attrs"
                    type="list-item-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader
                    v-bind="attrs"
                    type="list-item-two-line"
                ></v-skeleton-loader>
              </div>
              <table class="table table-stripe" v-else>
                <thead>
                <tr>

                  <th>
                    <v-checkbox @change="selectAll()" v-model="checkAll" outlined dense
                                label="Full Name"></v-checkbox>
                  </th>
                  <th>CRN No.</th>
                </tr>
                </thead>
                <tbody v-if="students && students.length>0">
                <tr v-for="(student, index) of students" :key="index">
                  <td>
                    <v-checkbox v-model="selectedStudents" :value="student.student_id" outlined
                                dense
                                v-bind:label="(student.full_name)?student.full_name:null"></v-checkbox>
                  </td>

                  <td>{{ student.user_personal_id ? student.user_personal_id : "N/A" }}
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="4" class="text-center">Data not available</td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </v-app>
</template>
<script>
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import ExamService from "@/core/services/exam/ExamService";

const examService = new ExamService();
export default {
  name: "AssignExamStudents",
  data() {
    return {
      search: {
        title: "",
      },
      assigned_students: {},
      selectedStudents: [],
      checkAll: [],
      dialog: false,
      loading: false,
      students: [],
      academicYears: [],
      selected: [],
      class_students: [],
      academic_id: null,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      academic_class: {
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        shift: null,
        title: null,
        is_active: true
      },
    };
  },
  computed: {
    examId() {
      return this.$route.params.exam_id;
    }
  },
  methods: {
    hideModal() {
      this.dialog = false;
    },

    openDialog() {
      this.dialog = true;
      this.getStudentExamFromClass();
    },

    selectAll() {
      if (!this.checkAll) {
        this.selectedStudents = [];
      } else {
        this.students.forEach(student => {
          this.selectedStudents.push(student.student_id);
        })
      }
    },
    setAssignedStudents(students) {
      if (students && students.length > 0) {
        students.forEach(ele => {
          if (ele.is_exam_assigned) {
            this.selectedStudents.push(ele.student_id);
          }
        })
      }
    },

    getStudentExamFromClass() {
      this.loading = true;
        examService.getExamAssignedStudents(this.examId).then(response => {
          this.students = response.data.students;
          this.loading = false;
          this.setAssignedStudents(this.students);
        }).catch(error => {
          this.loading = false;
        })
    },

    save() {
      if(this.selectedStudents.length<1)
      {
        this.$snotify.error("Please select students to assign");
      }else
      {
        let data = this.buildData();
        this.$confirm({
          message: `Are you sure?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          callback: confirm => {
            if (confirm) {
              this.loading = true
              examService.saveExamAssignedStudents(this.examId, data).then((response) => {
                if (response.data.status == "OK") {
                  this.$emit('refresh');
                  this.resetForm();
                  this.$snotify.success("Information updated");
                }
              }).catch(error => {
                this.$snotify.error("Something went wrong");
              }).finally(() => {
                this.loading = false
              })
            }
          }
        });
      }
    },

    buildData() {
      let result = []
      if (this.students && this.students.length > 0) {
        this.students.forEach(ele => {
          if (this.selectedStudents.includes(ele.student_id))
          {
            result.push(ele.student_id);
          }

        })
      }
      return result;
    },

    resetForm() {
      this.selectedStudents = [];
      this.students = [];
      this.hideModal();

    }
  }
};
</script>
